import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Container from "../components/Container"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => {
  const people = [
    {
      name: "Henrik Salmela",
      role: "CEO",
      Image: (
        <StaticImage
          width={80}
          className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
          src="../images/henkka.jpg"
          alt="Henrik Salmela"
        />
      ),
    },
    {
      name: "Essi Kivivuori",
      role: "Marketing",
      Image: (
        <StaticImage
          width={80}
          className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
          src="../images/essi.jpg"
          alt="Essi Kivivuori"
        />
      ),
    },
    {
      name: "Jaakko lamminpää",
      role: "Content Creator",
      Image: (
        <StaticImage
          width={80}
          className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
          src="../images/jaakko.jpg"
          alt="Jaakko lamminpää"
        />
      ),
    },
    {
      name: "Eetu Laaksonen",
      role: "Co-Founder",
      Image: (
        <StaticImage
          width={80}
          className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
          src="../images/eetu.jpg"
          alt="Eetu Laaksonen"
        />
      ),
    },
    {
      name: "Oskari Palmu",
      role: "Sales",
      Image: (
        <StaticImage
          width={80}
          className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
          src="../images/osku.jpg"
          alt="Oskari Palmu"
        />
      ),
    },
  ]

  return (
    <Layout>
      <SEO
        // jsonLd={[localBusinessTurkuLD, localBusinessHelsinkiLD]}
        title="Meistä"
        description="Vivecho on tehokas ja ketterä kumppani digitaalisten tuotteiden ja palveluiden luonnissa. Koko henkilöstöllämme on vahva ohjelmointitausta."
        pageImage={metaImg}
        pageUrl={links.aboutUs}
      />

      <section id="hero">
        <Hero>
          <HeroTitle>
            <h1>
              <span className="block">Yhteystiedot</span>
            </h1>
            <span className="block text-indigo-400">ota yhteyttä</span>
          </HeroTitle>
          <HeroLead>
            Vivecho on tehokas ja ketterä kumppani digitaalisten tuotteiden ja
            palveluiden luonnissa. Olemme kansainvälinen ohjelmistoyritys ja
            yrityksessämme toimii digitalisaation asiantunijoita, kuin
            digitaalisiin ja fyysisiin kokonaisuuksien rajapintoihin
            erikoistuneita osaajia. Koko henkilöstöllämme on vahva
            ohjelmointitausta.
          </HeroLead>
        </Hero>
      </section>

      <section>
        <Container>
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
                Me
              </h2>
              <p className="text-xl text-gray-500">
                Osaamistamme ovat mm. web-, android-, ios-alustojen ohjelmointi.
                Erilaiset pilvi- ja IoT-ratkaisut.
              </p>
              <p className="text-xl text-gray-500">+358 9 4270 4752</p>
              <p className="text-xl text-gray-500">info@vivecho.com</p>
              <p className="text-xl text-gray-500">Joukahaisenkatu, Turku</p>
              <p className="text-xl text-gray-500">Itämerenkatu, Helsinki</p>
              <p className="text-xl text-gray-500">Postiosoite: Mikäli haluatte lähettää meille kirjepostia. Ota meihin yhteyttä niin annamme Teille postiosoitteemme.</p>
            </div>
            <div className="lg:col-span-2">
              <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
                {people.map(person => (
                  <li key={person.name}>
                    <div className="flex items-center space-x-4 lg:space-x-6">
                      {person.Image}
                      <div className="font-medium text-lg leading-6 space-y-1">
                        <p>{person.name}</p>
                        <p className="text-indigo-600">{person.role}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}
